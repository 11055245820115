import React, { useState } from 'react'
import Layout from '../components/layout'
import './contact.scss'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

export default function Contact() {

    const [formState, setFormState] = useState({
        name: "",
        email: "",
        tel: "",
        objet: "",
        message: ""
    })

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const handleChange = e => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
            [e.target.email]: e.target.value,
            [e.target.tel]: e.target.value,
            [e.target.objet]: e.target.value,
            [e.target.message]: e.target.value
        })
    }

    const handleSubmit = e => {

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...formState })
        })
            .then(() => alert("Votre message a bien été envoyé."))
            .catch(error => alert(error));

        e.preventDefault();
    }

    return (
        <Layout>
            <div id="contact">
                <section className="page-title">
                    <StaticImage
                        src="../images/fond-contact.jpg"
                        alt=""
                        placeholder="blurred"
                    />
                    <div className="auto-container relative">
                        <div className="content-box">
                            <div className="content-wrapper">
                                <div className="title">
                                    <h1>Contact</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overlay_absolute_6" />
                </section>

                <section className="infos-form">
                    <div className="container">
                        <h2 className="after_title">Informations de contact</h2>
                    </div>
                    <div className="container flex_wrap">
                        <div className="coordonnees width50">
                            <p>Vous pouvez nous contacter par email, par téléphone, ou en remplissant le formulaire ci-contre.</p>
                            <div className="coordonnee flex_wrap">
                                <div>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                </div>
                                <div>
                                    <h4>Adresse</h4>
                                    <p>Beaufort<br />
                                        <a href="https://goo.gl/maps/wsNE2UXun8AoTPsaA" target="_blank" rel="noreferrer">
                                            40 route de Domelin, 73270 Beaufort
                                        </a>
                                    </p>
                                    <p>Les Saisies<br />
                                        <a href="https://goo.gl/maps/rSh4Kscu5B6cAmdbA" target="_blank" rel="noreferrer">
                                            Rte du Mont Bisanne, 73620 Hauteluce
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="coordonnee flex_wrap">
                                <div>
                                    <FontAwesomeIcon icon={faPhoneAlt} />
                                </div>
                                <div>
                                    <h4>Téléphone</h4>
                                    <a href="tel:+33479383324">Beaufort 04 79 38 33 24</a><br /><br />
                                    <a href="tel:+33479399034">Les Saisies 04 79 39 90 34</a><br /><br />
                                    <a href="tel:+33786122846">07 86 12 28 46</a>
                                </div>
                            </div>
                            <div className="coordonnee flex_wrap">
                                <div>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                                <div>
                                    <h4>Email</h4>
                                    <a href="mailto:joel.avocat@wanadoo.fr">joel.avocat@wanadoo.fr</a><br /><br />
                                    <a href="mailto:avocat.carrosserie@orange.fr">avocat.carrosserie@orange.fr</a>
                                </div>
                            </div>
                        </div>
                        <div className="width50">
                            <form id="id_form_contact" className="form_contact" name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" onSubmit={handleSubmit}>
                                <div className="flex_wrap_between">
                                    <input
                                        type="hidden"
                                        name="form-name"
                                        value="formulaire"
                                    />
                                    <input
                                        id="name"
                                        type="text"
                                        name="name"
                                        onChange={handleChange}
                                        value={formState.name}
                                        placeholder="Votre Nom *"
                                        required
                                    />
                                    <div className="mail-tel">
                                        <input
                                            id="email"
                                            type="email"
                                            name="email"
                                            onChange={handleChange}
                                            value={formState.email}
                                            placeholder="Votre Adresse Email *"
                                            required
                                        />
                                    </div>
                                    <div className="mail-tel">
                                        <input
                                            id="tel"
                                            type="tel"
                                            name="tel"
                                            onChange={handleChange}
                                            value={formState.tel}
                                            placeholder="Votre Téléphone"
                                        />
                                    </div>
                                    <input
                                        id="objet"
                                        type="text"
                                        name="objet"
                                        onChange={handleChange}
                                        value={formState.objet}
                                        placeholder="Objet"
                                    />
                                    <textarea
                                        className=""
                                        name="message"
                                        placeholder="Votre Message... *"
                                        onChange={handleChange}
                                        required>
                                    </textarea>
                                    <div className="">
                                        <button type="submit" className="primary_button button">
                                            <span className="texte_lien_span">Envoyer</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>

                <div className="maps width_1200">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11142.202028495978!2d6.562788815344227!3d45.7200536!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x774e25378e1fe01b!2sGarage%20Avocat!5e0!3m2!1sfr!2sfr!4v1625400124614!5m2!1sfr!2sfr"
                        width="100%"
                        height="600"
                        style={{ border: 0 }}
                        title="Google Maps Alp'Auto Ecole"
                        allowfullscreen=""
                        loading="lazy"
                    >
                    </iframe>
                </div>
            </div>
        </Layout>
    )
}